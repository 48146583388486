import { Stack, xcss } from '@atlaskit/primitives';
import debounce from 'lodash.debounce';
import React, { Suspense, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl-next';
import { graphql, PreloadedQuery, usePreloadedQuery } from 'react-relay';
import { RouteContext } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { useOnMount } from '@townsquare/hooks';
import { LoadingState } from '@townsquare/loading-state';
import {
  CapabilitySubject,
  FullPageViewErrorBoundary,
  ExperienceConfigPreset,
  ExperienceSuccess,
  UFOExperience,
  useExperienceTracker,
} from '@townsquare/monitoring';
import { PageTitle } from '@townsquare/page-title';
import { SubmitPageLoadMetrics } from '@townsquare/performance';
import { useRelayResource } from '@townsquare/relay-utils';
import { MainColumn, ResponsivePageWithSidebar, ResponsiveSection } from '@townsquare/responsive-grid';
import { SortButtonProps } from '@townsquare/sort-button';
import { useIsHomeVisualRefreshEnabled, useIsNavRefreshEnabled } from '@townsquare/stat-sig/nav4';
import { EMPTY_FILTER_DOC, inputFromTQLExplanation, tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator, type ExplainedInput, type FilterDoc, type Resolvers } from '@townsquare/tql/types';
import { useWorkspaceStore } from '@townsquare/workspace-store';
import { useCPUSScope } from '@townsquare/workspace-store/hooks';

import { useCloudIdQueryParam, useScreenQueryParam, useSortQueryParam, useTqlQueryParam } from '../hooks/queryParams';
import { useRecentlySearched } from '../hooks/recent';
import { StaffDirectoryPageLoad } from '../performanceMetrics';
import { staffDirectoryResource } from '../resources';

import { DirectorySortEnum } from './BrowsePeopleSection/__generated__/DefaultSearchFallBackPeopleSearchQuery.graphql';
import { DirectoryHeader } from './DirectoryHeader';
import { filterRestrictionForScreen, SEARCH_ENABLED_SCREEN_TYPES } from './ScreenType';
import { FilterSelection, getFilterResolvers, getFilterSelection } from './SearchBar/FilterResolvers';
import { getSearchPlaceholderText } from './SearchBar/PlaceholderText';
import { SearchBarProps, SearchQuery } from './SearchBar/types';
import {
  DEFAULT_TEAMS_SORT,
  getTeamSortOptions,
  isPeopleAndTeamsSortType,
  isTeamSortEnum,
  PeopleAndTeamsSortType,
} from './SortOptions';
import { StaffDirectoryViewQuery } from './__generated__/StaffDirectoryViewQuery.graphql';
import { BrowseAllScreen, BrowseAllScreenSkeleton } from './screens/BrowseAllScreen';
import { BrowsePeopleFieldsScreen } from './screens/BrowsePeopleFieldsScreen';
import { PEOPLE_FIELD_SCREEN_TYPES, SEARCH_BAR_PREVIEW_TQL_SCREEN_TYPES, ScreenType } from './screens/ScreenType';
import { SearchAllScreen } from './screens/SearchAllScreen';
import { SearchKudosScreen } from './screens/SearchKudosScreen';
import { SearchPeopleScreen } from './screens/SearchPeopleScreen';
import { SearchTeamsScreen } from './screens/SearchTeamsScreen';
import { ClearSearchQueryFn } from './types';

const EMPTY_SEARCH_QUERY = { searchText: undefined, filterDoc: EMPTY_FILTER_DOC };

const headerStyles = xcss({
  paddingBlockStart: 'space.100',
});

const DirectoryFrame = ({ children }: { children: React.ReactNode }) => {
  const enableNavRefresh = useIsNavRefreshEnabled();
  const intl = useIntl();
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  const content = (
    <>
      <PageTitle
        title={intl.formatMessage({
          id: 'townsquare.web.staff-directory-view.page-title',
          description: 'Page title for the staff directory view page',
          defaultMessage: 'People and Teams',
        })}
      />
      <Stack
        space={enableHomeVisualRefresh ? 'space.400' : 'space.300'}
        xcss={enableNavRefresh ? undefined : headerStyles}
      >
        {children}
      </Stack>
    </>
  );

  if (enableNavRefresh) {
    return content;
  }

  return (
    <ResponsivePageWithSidebar customWidth={1280} data-testid="relay-staff-directory">
      <ResponsiveSection>
        <MainColumn>{content}</MainColumn>
      </ResponsiveSection>
    </ResponsivePageWithSidebar>
  );
};

const DirectoryRenderedScreenSkeleton = ({ screen }: { screen: ScreenType }) => {
  switch (screen) {
    case ScreenType.SEARCH_PEOPLE:
    case ScreenType.SEARCH_TEAMS:
    case ScreenType.SEARCH_ALL:
    case ScreenType.BROWSE_DEPARTMENTS:
    case ScreenType.BROWSE_JOB_TITLES:
    case ScreenType.BROWSE_LOCATIONS:
    case ScreenType.BROWSE_KUDOS:
      return <LoadingState />;
    case ScreenType.BROWSE_ALL:
    default:
      return <BrowseAllScreenSkeleton />;
  }
};

const DirectoryRenderedScreen = ({
  clearSearchQuery,
  directoryResolvers,
  queryRef,
  screen,
  searchBarTextFieldRef,
  setFilterSelection,
  setHideTeamSort,
  setSearchQuery,
  sortProps,
  tql,
  updateScreenType,
  updateSort,
}: {
  clearSearchQuery: ClearSearchQueryFn;
  directoryResolvers: Resolvers;
  queryRef: PreloadedQuery<StaffDirectoryViewQuery>;
  screen: ScreenType;
  searchBarTextFieldRef: React.RefObject<HTMLInputElement>;
  setFilterSelection: (value: FilterSelection) => void;
  setHideTeamSort: (value: boolean) => void;
  setSearchQuery: (value: SearchQuery) => void;
  sortProps?: SortButtonProps<DirectorySortEnum>;
  tql: string;
  updateScreenType: (newScreen: ScreenType) => void;
  updateSort: (newSort: PeopleAndTeamsSortType | undefined) => void;
}) => {
  const data = usePreloadedQuery<StaffDirectoryViewQuery>(
    graphql`
      query StaffDirectoryViewQuery(
        $teamQuery: String!
        $userQuery: String!
        $kudosQuery: String!
        $first: Int
        $organisationId: String!
        $workspaceId: ID!
        $cloudId: String!
        $searchTeamsSort: [DirectoryTeamSortEnum]
        $peopleFieldType: PeopleFieldType!
        $isSearchAllScreen: Boolean!
        $isSearchPeopleScreen: Boolean!
        $isSearchTeamsScreen: Boolean!
        $isBrowseAllScreen: Boolean!
        $isBrowsePeopleScreen: Boolean!
        $isSearchKudosScreen: Boolean!
      ) {
        explainTql(q: $userQuery, entity: PERSON) {
          explanation
        }

        ...SearchAllScreen_data
          @arguments(
            teamQuery: $teamQuery
            userQuery: $userQuery
            organisationId: $organisationId
            cloudId: $cloudId
            first: $first
            sort: $searchTeamsSort
            isSearchAllScreen: $isSearchAllScreen
          )
        ...SearchPeopleScreen_data
          @arguments(
            query: $userQuery
            organisationId: $organisationId
            cloudId: $cloudId
            first: $first
            isSearchPeopleScreen: $isSearchPeopleScreen
          )
        ...SearchTeamsScreen_data
          @arguments(
            query: $teamQuery
            organisationId: $organisationId
            cloudId: $cloudId
            first: $first
            sort: $searchTeamsSort
            isSearchTeamsScreen: $isSearchTeamsScreen
          )
        ...BrowseAllScreenQuery
          @include(if: $isBrowseAllScreen)
          @arguments(
            query: $teamQuery
            kudosQuery: $kudosQuery
            organisationId: $organisationId
            cloudId: $cloudId
            workspaceId: $workspaceId
            first: 4
            kudosFirst: 5
          )

        ...BrowsePeopleFieldsScreenQuery
          @include(if: $isBrowsePeopleScreen)
          @arguments(query: $userQuery, peopleFieldType: $peopleFieldType, workspaceId: $workspaceId)
        ...SearchKudosScreen_data
          @arguments(query: $kudosQuery, workspaceId: $workspaceId, isSearchKudosScreen: $isSearchKudosScreen)
      }
    `,
    queryRef,
  );

  const initialTqlExplanation = useMemo(
    () => inputFromTQLExplanation(data.explainTql?.explanation, directoryResolvers),
    [data.explainTql?.explanation, directoryResolvers],
  );

  // We try this in two places because the flow of reacting to browser navigation is really messy, and other solutions can result in the search bar being overwritten incorrectly
  const updateSearchBar = useCallback(
    (filterDoc: FilterDoc, input: ExplainedInput) => {
      const searchText = input.get('name')?.toString() ?? EMPTY_SEARCH_QUERY.searchText;

      if (searchBarTextFieldRef.current) {
        // This is how we update the text shown in the search bar
        searchBarTextFieldRef.current.value = searchText ?? '';
      }

      setSearchQuery({ searchText, filterDoc });
      setFilterSelection(getFilterSelection(filterDoc));
    },
    [searchBarTextFieldRef, setFilterSelection, setSearchQuery],
  );

  // This only runs when the tql query param is updated, to update the search bar
  useOnMount(() => {
    if (initialTqlExplanation) {
      updateSearchBar(...initialTqlExplanation);
    } else {
      setSearchQuery(EMPTY_SEARCH_QUERY);
    }
  });

  return useMemo(() => {
    switch (screen) {
      case ScreenType.SEARCH_PEOPLE: {
        return <SearchPeopleScreen data={data} clearSearchFn={clearSearchQuery} tql={tql} />;
      }
      case ScreenType.SEARCH_TEAMS: {
        return (
          <SearchTeamsScreen
            data={data}
            clearSearchFn={clearSearchQuery}
            tql={tql}
            setHideTeamSort={setHideTeamSort}
            teamSort={[sortProps?.currentSort ? sortProps.currentSort : DEFAULT_TEAMS_SORT]}
          />
        );
      }
      case ScreenType.SEARCH_ALL:
        return (
          <SearchAllScreen data={data} clearSearchQuery={clearSearchQuery} tql={tql} updateScreen={updateScreenType} />
        );
      case ScreenType.BROWSE_KUDOS:
        return <SearchKudosScreen data={data} tql={tql} clearSearchFn={clearSearchQuery} />;
      case ScreenType.BROWSE_DEPARTMENTS:
      case ScreenType.BROWSE_JOB_TITLES:
      case ScreenType.BROWSE_LOCATIONS:
        return <BrowsePeopleFieldsScreen data={data} screenType={screen} />;
      case ScreenType.BROWSE_ALL:
      default:
        updateSort(undefined);
        return <BrowseAllScreen data={data} />;
    }
  }, [screen, setHideTeamSort, tql, sortProps, data, clearSearchQuery, updateScreenType, updateSort]);
};

type DirectoryProps =
  | { loading: true }
  | (RouteContext & {
      loading: false;
      queryRef: PreloadedQuery<StaffDirectoryViewQuery>;
      experience: UFOExperience;
    });

const Directory = (props: DirectoryProps) => {
  const [screen, setScreen] = useScreenQueryParam(props.loading ? undefined : props.query.screen);
  const [tql, setTql] = useTqlQueryParam();
  const [searchQuery, setSearchQuery] = useState<SearchQuery>(EMPTY_SEARCH_QUERY);

  const [hideTeamSort, setHideTeamSort] = useState<boolean>(false);
  const [filterSelection, setFilterSelection]: [
    FilterSelection,
    (value: ((prevState: FilterSelection) => FilterSelection) | FilterSelection) => void,
  ] = useState<FilterSelection>('unknown');

  const searchBarTextFieldRef = useRef<HTMLInputElement>(null);

  const intl = useIntl();
  const [{ cloudId, globalId, organisationId, UUID: workspaceUuid }] = useWorkspaceStore();
  const cpusScope = useCPUSScope({ isInviteRelated: false });

  const TEAMS_SORT_OPTIONS = getTeamSortOptions(intl);

  const directoryResolvers = useMemo<Resolvers>(() => {
    return cloudId && organisationId
      ? getFilterResolvers(
          intl,
          { cloudId, orgId: organisationId, workspaceId: globalId, workspaceUuid, cpusScope },
          filterRestrictionForScreen(filterSelection, screen),
        )
      : [];
  }, [cloudId, organisationId, intl, globalId, workspaceUuid, cpusScope, filterSelection, screen]);

  const analytics = useAnalytics();
  useOnMount(() => {
    void analytics.page('staffDirectoryScreen', { screenType: ScreenType[screen], hasTql: !!tql });
  });

  const { recordSearch } = useRecentlySearched();

  const [sort, setSort] = useState<PeopleAndTeamsSortType | undefined>(() => {
    const sortFromQuery = props.loading ? tql : props.query.sort;
    if (sortFromQuery && isPeopleAndTeamsSortType(sortFromQuery)) {
      return sortFromQuery;
    }

    return undefined;
  });

  const createOnSearchFunction = useCallback(() => {
    return debounce((query: SearchQuery) => {
      if (!query.searchText && searchBarTextFieldRef.current) {
        // This is how 'clear filters' clears the search bar value
        searchBarTextFieldRef.current.value = '';
      }

      setSearchQuery(query);

      const { filterDoc, searchText } = query;

      const newTql = tqlQueryFromInput({
        doc: filterDoc,
        input: [
          {
            fieldName: 'name',
            fieldValue: searchText || undefined, // check if falsey because a blank string for name breaks CPUS search
            comparator: ComparatorOperator.LIKE,
          },
        ],
      });

      setTql(newTql);
      setFilterSelection(getFilterSelection(filterDoc));

      recordSearch(query, screen);
    }, 250);
  }, [setTql, recordSearch, screen]);

  const onSearch = createOnSearchFunction();

  // Control setting query params and defaults if not given
  useCloudIdQueryParam();
  const [, setSortQueryParam] = useSortQueryParam(sort, setSort);

  const updateSort = useCallback(
    (newSort: PeopleAndTeamsSortType | undefined) => {
      if (sort !== newSort) {
        setSort(newSort);
        setSortQueryParam(newSort, 'replace');
      }
    },
    [setSortQueryParam, sort],
  );

  const clearSearchQuery: ClearSearchQueryFn = useCallback(() => {
    onSearch.cancel();
    onSearch(EMPTY_SEARCH_QUERY);
    setTql(undefined);
    updateSort(undefined);
  }, [onSearch, setTql, updateSort]);

  useEffect(() => {
    // Since it's an SPA, scroll to top when screen type changes
    window.scrollTo(0, 0);
  }, [screen]);

  const updateScreenType = useCallback(
    (newScreen: ScreenType) => {
      setScreen(newScreen, 'replace');
    },
    [setScreen],
  );

  useEffect(() => {
    if (!tql) {
      // There's a race condition with RRR when setting two query params close together.
      // If we wait until the tql query param value is updated, we can avoid that here.
      return;
    }

    if (screen === ScreenType.BROWSE_ALL && filterSelection !== 'none' && filterSelection !== 'unknown') {
      updateScreenType(ScreenType.SEARCH_ALL);
    } else if (
      filterSelection !== 'none' &&
      filterSelection !== 'unknown' &&
      PEOPLE_FIELD_SCREEN_TYPES.includes(screen)
    ) {
      updateScreenType(ScreenType.SEARCH_PEOPLE);
    }
  }, [screen, filterSelection, tql, updateScreenType]);

  const showSearchBar = SEARCH_ENABLED_SCREEN_TYPES.includes(screen);

  const sortProps: SortButtonProps<DirectorySortEnum> | undefined = useMemo(() => {
    const updateSortWithButtonAnalytics: typeof updateSort = newSort => {
      updateSort(newSort);

      void analytics.ui('staffDirectorySortButton', 'changed', {
        screen: ScreenType[screen],
        order: newSort,
      });
    };

    if (screen !== ScreenType.SEARCH_TEAMS || hideTeamSort) {
      return undefined;
    } else {
      const currentTeamSort = isTeamSortEnum(sort) ? sort : DEFAULT_TEAMS_SORT;
      updateSort(currentTeamSort);
      return {
        currentSort: currentTeamSort,
        updateSort: updateSortWithButtonAnalytics,
        sortOptions: TEAMS_SORT_OPTIONS,
      };
    }
  }, [screen, sort, updateSort, hideTeamSort, TEAMS_SORT_OPTIONS, analytics]);

  const searchBarProps: Omit<SearchBarProps, 'loading'> = useMemo(() => {
    return {
      onSearch: onSearch,
      directoryResolvers: directoryResolvers,
      hasFilters: !!tql,
      searchQuery: searchQuery,
      clearSearchQuery: clearSearchQuery,
      textFieldRef: searchBarTextFieldRef,
      textFieldPlaceholder: getSearchPlaceholderText(screen, intl),
      showRecent: SEARCH_BAR_PREVIEW_TQL_SCREEN_TYPES.includes(screen),
      searchBarPreviewTql: SEARCH_BAR_PREVIEW_TQL_SCREEN_TYPES.includes(screen) ? tql : undefined,
    };
  }, [clearSearchQuery, directoryResolvers, intl, onSearch, screen, searchQuery, tql]);

  const directoryBodySkeleton = useMemo(() => <DirectoryRenderedScreenSkeleton screen={screen} />, [screen]);

  return (
    <DirectoryFrame>
      <DirectoryHeader
        loading={props.loading}
        screen={screen}
        searchBarProps={searchBarProps}
        showSearchBar={showSearchBar}
        sortProps={sortProps}
      />
      {props.loading ? (
        directoryBodySkeleton
      ) : (
        <>
          <Suspense fallback={directoryBodySkeleton}>
            <DirectoryRenderedScreen
              clearSearchQuery={clearSearchQuery}
              directoryResolvers={directoryResolvers}
              queryRef={props.queryRef}
              screen={screen}
              searchBarTextFieldRef={searchBarTextFieldRef}
              setFilterSelection={setFilterSelection}
              setHideTeamSort={setHideTeamSort}
              setSearchQuery={setSearchQuery}
              sortProps={sortProps}
              tql={tql || ''}
              updateScreenType={updateScreenType}
              updateSort={updateSort}
            />
          </Suspense>
          <SubmitPageLoadMetrics metric={StaffDirectoryPageLoad} />
          <ExperienceSuccess experience={props.experience} />
        </>
      )}
    </DirectoryFrame>
  );
};

export const DirectorySkeleton = () => <Directory loading={true} />;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export default (props: RouteContext) => {
  const queryRef = useRelayResource(staffDirectoryResource);
  const experience = useExperienceTracker(CapabilitySubject.StaffDirectoryViewed, ExperienceConfigPreset.LoadPageLoad);

  if (!queryRef) {
    return null;
  }

  return (
    <FullPageViewErrorBoundary experience={experience}>
      <Directory {...props} loading={false} queryRef={queryRef} experience={experience} />
    </FullPageViewErrorBoundary>
  );
};
