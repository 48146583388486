import React, { useEffect, useRef } from 'react';
import { graphql, usePaginationFragment } from 'react-relay';

import { useAnalytics } from '@townsquare/analytics';
import { filterNull } from '@townsquare/filter-type';
import { useOnMount } from '@townsquare/hooks';
import { KudosContent } from '@townsquare/kudos-on-feed';

import { EmptySearchState } from '../EmptySearchState/SearchEmptyState';
import { PaginationErrorState } from '../PaginationErrorState/PaginationErrorState';
import { ClearSearchQueryFn } from '../types';

import { ScrollingResultsScreen } from './ScrollingResultsScreen';
import { SearchKudosScreenPaginationQuery } from './__generated__/SearchKudosScreenPaginationQuery.graphql';
import { SearchKudosScreen_data$key } from './__generated__/SearchKudosScreen_data.graphql';

const PAGE_SIZE = 12;

interface SearchKudosScreenProps {
  data: SearchKudosScreen_data$key;
  tql?: string;
  clearSearchFn: ClearSearchQueryFn;
}

export const SearchKudosScreen = (props: SearchKudosScreenProps) => {
  const hasMounted = useRef(false);
  const analytics = useAnalytics();

  useOnMount(() => {
    hasMounted.current = true;
    void analytics.ui('KudosSearchScreen', 'viewed');
  });

  const { data, loadNext, hasNext, isLoadingNext, refetch } = usePaginationFragment<
    SearchKudosScreenPaginationQuery,
    SearchKudosScreen_data$key
  >(
    graphql`
      fragment SearchKudosScreen_data on Query
      @refetchable(queryName: "SearchKudosScreenPaginationQuery")
      @argumentDefinitions(
        query: { type: "String!" }
        first: { type: "Int", defaultValue: 12 }
        after: { type: "String" }
        workspaceId: { type: "ID" }
        isSearchKudosScreen: { type: "Boolean", defaultValue: false }
      ) {
        kudosTql(q: $query, workspaceId: $workspaceId, first: $first, after: $after)
          @include(if: $isSearchKudosScreen)
          @connection(key: "SearchKudosScreen_kudosTql") {
          edges {
            node {
              ...KudosContent
            }
          }
        }
      }
    `,
    props.data,
  );

  useEffect(() => {
    if (!hasMounted.current) {
      return;
    }
    refetch({ query: props.tql ?? '' });
  }, [props.tql, refetch]);

  const kudosItems =
    data.kudosTql?.edges
      ?.map(edge => {
        return (
          edge?.node && (
            <KudosContent data={edge.node} kudosPerPage={4} showAllDescriptionRows={false} cardSize="large" />
          )
        );
      })
      .filter(filterNull) ?? [];

  return kudosItems.length ? (
    <ScrollingResultsScreen
      resultCount={data.kudosTql?.edges?.length ?? 0}
      resultName="kudos"
      resultList={kudosItems}
      renderFallback={fallbackProps => (
        <PaginationErrorState
          error={fallbackProps.error}
          resetErrorBoundary={fallbackProps.resetErrorBoundary}
          resultType="kudos"
        />
      )}
      isLoading={isLoadingNext}
      loadMore={loadNext}
      hasMore={hasNext}
      pageSize={PAGE_SIZE}
    />
  ) : (
    <EmptySearchState resultType="kudos" clearSearchQuery={props.clearSearchFn} />
  );
};
