/**
 * @generated SignedSource<<16279184f54a448bea0e56b00407159c>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { ConcreteRequest, Query } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type WorkspaceContextType = "Default" | "DirectoryViewUuid" | "GoalKey" | "ProjectKey" | "TagId" | "TagUuid" | "WorkspaceCloudId" | "WorkspaceIdentifier" | "WorkspaceInOrgByCloudId";
export type WorkspaceContextInput = {
  contextType: WorkspaceContextType;
  identifier?: string | null;
};
export type CapabilityProviderQuery$variables = {
  isValidWorkspace: boolean;
  workspaceContext: WorkspaceContextInput;
};
export type CapabilityProviderQuery$data = {
  readonly userWorkspaceByContext?: {
    readonly workspace: {
      readonly " $fragmentSpreads": FragmentRefs<"utils_checkUserHasCapabilities">;
    } | null;
  } | null;
};
export type CapabilityProviderQuery = {
  response: CapabilityProviderQuery$data;
  variables: CapabilityProviderQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "isValidWorkspace"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workspaceContext"
},
v2 = [
  {
    "kind": "Variable",
    "name": "workspaceContext",
    "variableName": "workspaceContext"
  }
],
v3 = {
  "kind": "ScalarField",
  "name": "capability"
},
v4 = {
  "kind": "ScalarField",
  "name": "capabilityContainer"
},
v5 = {
  "kind": "ScalarField",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "name": "CapabilityProviderQuery",
    "selections": [
      {
        "condition": "isValidWorkspace",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "UserWorkspaceByContextResponse",
            "kind": "LinkedField",
            "name": "userWorkspaceByContext",
            "plural": false,
            "selections": [
              {
                "concreteType": "Workspace",
                "kind": "LinkedField",
                "name": "workspace",
                "plural": false,
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "utils_checkUserHasCapabilities",
                    "selections": [
                      {
                        "concreteType": "UserCapabilities",
                        "kind": "LinkedField",
                        "name": "currentUserAppCapabilities",
                        "plural": false,
                        "selections": [
                          {
                            "concreteType": "Capability",
                            "kind": "LinkedField",
                            "name": "capabilities",
                            "plural": true,
                            "selections": [
                              (v3/*: any*/),
                              (v4/*: any*/)
                            ]
                          }
                        ]
                      }
                    ],
                    "argumentDefinitions": []
                  }
                ]
              }
            ]
          }
        ]
      }
    ],
    "type": "Query"
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CapabilityProviderQuery",
    "selections": [
      {
        "condition": "isValidWorkspace",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "args": (v2/*: any*/),
            "concreteType": "UserWorkspaceByContextResponse",
            "kind": "LinkedField",
            "name": "userWorkspaceByContext",
            "plural": false,
            "selections": [
              {
                "concreteType": "Workspace",
                "kind": "LinkedField",
                "name": "workspace",
                "plural": false,
                "selections": [
                  {
                    "concreteType": "UserCapabilities",
                    "kind": "LinkedField",
                    "name": "currentUserAppCapabilities",
                    "plural": false,
                    "selections": [
                      {
                        "concreteType": "Capability",
                        "kind": "LinkedField",
                        "name": "capabilities",
                        "plural": true,
                        "selections": [
                          (v3/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ]
                      }
                    ]
                  },
                  (v5/*: any*/)
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "05ab9b90b7400f7742aa26f890a79c4f",
    "id": null,
    "metadata": {},
    "name": "CapabilityProviderQuery",
    "operationKind": "query",
    "text": "query CapabilityProviderQuery(\n  $workspaceContext: WorkspaceContextInput!\n  $isValidWorkspace: Boolean!\n) {\n  userWorkspaceByContext(workspaceContext: $workspaceContext) @include(if: $isValidWorkspace) {\n    workspace {\n      ...utils_checkUserHasCapabilities\n      id\n    }\n  }\n}\n\nfragment utils_checkUserHasCapabilities on Workspace {\n  currentUserAppCapabilities {\n    capabilities {\n      capability\n      capabilityContainer\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "4d038ef15232f0abf1db023bed8b7f97";

export default node;
