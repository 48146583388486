import FeatureGates from '@atlaskit/feature-gate-js-client';
import { captureException } from '@sentry/react';
import { History } from 'history';
import React, { ReactNode, useCallback, useEffect, useMemo } from 'react';
import { graphql, readInlineData, useRelayEnvironment } from 'react-relay';
import { matchRoute } from 'react-resource-router';

import { useAnalytics } from '@townsquare/analytics';
import { AppState, useAppStateStore } from '@townsquare/app-state-store';
import { getOnboardingLinkWithContinueUrl } from '@townsquare/auth-utils';
import { resolveActiveWorkspaceByContext } from '@townsquare/bootstrap';
import { CollabContext, type CollaborationContext } from '@townsquare/collab-context';
import { getConfig } from '@townsquare/config';
import { HOME_PAGE, JIRA_INTEGRATION_READY_PATH, ONBOARDING_PAGE } from '@townsquare/config/routes';
import { isCrossFlowing } from '@townsquare/crossflow/params';
import { redirect } from '@townsquare/facade';
import { featureFlagClient, initFeatureFlagClient } from '@townsquare/feature-flags';
import { FeatureFlagClientInterface } from '@townsquare/feature-flags/types';
import { setSentryAAID, setSentryCloudId } from '@townsquare/logging';
import { HelpContextProvider } from '@townsquare/navigation';
import { initConfigClient } from '@townsquare/stat-sig/config';
import { featureGatesOptions } from '@townsquare/stat-sig/options';
import { createSearchParamsFromCurrentUrl, createSearchParamsFromScratch } from '@townsquare/url-utils/search-params';
import { type User, useUserStore } from '@townsquare/user-store';
import { useWorkspaceStore, type WorkspaceDetails } from '@townsquare/workspace-store';

import { getRoutes } from '../routes';
import { FullScreenSpaLoader } from '../ui/FullScreenSpaLoader';
import { useAsyncEffect } from '../util/hooks';
import { getWorkspaceContext } from '../util/workspace';

import { isUserOnboarded } from './UserOnboardedQuery';
import { BootstrapWorkspaceData$key } from './__generated__/BootstrapWorkspaceData.graphql';
import { globexRedirect } from './globex-redirect';
import { updateUserWorkspacePreference } from './mutations/UpdateUserWorkspacePreference';

type BootstrapProps = {
  history: History<unknown>;
  children: (user: User, workspace: WorkspaceDetails, featureFlags: FeatureFlagClientInterface) => ReactNode;
};

const config = getConfig();

export const Bootstrap = (props: BootstrapProps) => {
  const routes = getRoutes();
  const urlSearch = Object.fromEntries(createSearchParamsFromCurrentUrl());
  const matchedRoute = matchRoute(routes, window.location.pathname, urlSearch);
  const cloudId = urlSearch.cloudId ?? undefined;
  const qsSrc = urlSearch.src || undefined;
  const qsStartCrossFlow = urlSearch.startCrossFlowSrcContext || undefined;
  const qsStartCrossFlowContinueUrl = `${config.fullUrl}${JIRA_INTEGRATION_READY_PATH}`;

  const analytics = useAnalytics();
  const [{ appState }, { setAppState }] = useAppStateStore();
  const [workspace, workspaceAction] = useWorkspaceStore();
  const [user, userAction] = useUserStore();
  const environment = useRelayEnvironment();

  const abortController = new AbortController();
  useEffect(() => {
    return () => {
      return abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialiseUserWithoutWorkspace = useCallback(
    async (accountId: string) => {
      // Initialise Analytics & FF clients without workspace context
      const analyticsClient = analytics.getAnalyticsWebClient();

      analytics.setCurrentUserAndTenantInfo(accountId);

      await initFeatureFlagClient({ analyticsClient, userAaid: accountId }).ready();

      setAppState(AppState.INITIALISED_WITHOUT_WORKSPACE);
      setSentryCloudId('no-workspace');
      return;
    },
    [analytics, setAppState],
  );

  useAsyncEffect(async () => {
    const userState = await userAction.initCurrentUser();
    setSentryAAID(userState.accountId);

    if (matchedRoute) {
      // Optimistically prefetch this as if the user has a workspace we will want to check their onboarded status
      const userOnboardedPromise = isUserOnboarded();
      const workspaceContext = await resolveActiveWorkspaceByContext(
        environment,
        cloudId,
        getWorkspaceContext(matchedRoute.route.path, userState.accountId, matchedRoute.match, cloudId),
        qsStartCrossFlow ? qsStartCrossFlowContinueUrl : undefined,
        abortController.signal,
      );

      // If workspaceContext returns true, it means a redirect cannot take place
      // Since the path we're currently on is the path the redirect is trying to go to
      // Which would either be `/noaccess` or `/your-work`, in these cases we want the rendering
      // of the app to continue.
      if (workspaceContext === true) {
        return initialiseUserWithoutWorkspace(userState.accountId);
      }

      if (workspaceContext?.workspaceByContext.__typename === 'WorkspaceByContextSuccessfulResponse') {
        workspaceAction.initWorkspaceFromGraph(workspaceContext.workspaceByContext, userState.accountId);

        const data = readInlineData<BootstrapWorkspaceData$key>(
          graphql`
            fragment BootstrapWorkspaceData on WorkspaceByContextSuccessfulResponse @inline {
              workspace {
                id
                uuid
                cloudId
                organisationId
                type
              }
            }
          `,
          workspaceContext.workspaceByContext,
        );

        // If we resolve a global experience workspace and we're not cross flowing, redirect to home
        // Otherwise attempt to respect the cross flow
        if (data.workspace?.type === 'GLOBAL_EXPERIENCE' && !isCrossFlowing()) {
          globexRedirect(matchedRoute, { orgId: data.workspace.organisationId, cloudId: data.workspace.cloudId });
          return;
        }

        const userOnboardedResult = await userOnboardedPromise;
        /**
         * If the user is not onboarded, redirect them to the onboarding page.
         * If this request fails, we don't want to redirect the user to the onboarding page.
         * As the request failure may be a result of rate limiting and bootstrapping will
         * cause a redirect to the no workspace experience which will cause a redirect loop.
         * A request failure is counted as `onboarded: true` as this functionality will recover
         * when this operation next succeeds.
         */
        if (
          matchedRoute.route.name !== ONBOARDING_PAGE &&
          !userOnboardedResult.onboarded &&
          !userOnboardedResult.error &&
          !matchedRoute.route.name.includes('kudos') &&
          !isCrossFlowing()
        ) {
          return redirect(getOnboardingLinkWithContinueUrl(cloudId));
        }

        if (data.workspace?.cloudId) {
          setSentryCloudId(data.workspace.cloudId);
        }

        // If our only query param is cloudId and route is the home page
        // probably coming from Atlassian Switcher, store as preferred workspace in the BE
        if (data.workspace && matchedRoute.route.name === HOME_PAGE && cloudId && Object.keys(urlSearch).length === 1) {
          updateUserWorkspacePreference(environment, { workspaceId: data.workspace.id });
        }

        const analyticsClient = analytics.getAnalyticsWebClient();
        void analytics.setCurrentUserAndTenantInfo(userState.accountId, data.workspace?.uuid, data.workspace?.cloudId);
        analyticsClient.setOrgInfo(data.workspace?.organisationId);

        await initFeatureFlagClient({
          analyticsClient,
          userAaid: userState.accountId,
          workspaceUuid: data.workspace?.uuid,
          workspaceType: data.workspace?.type,
          cloudId: data.workspace?.cloudId,
        }).ready();

        await FeatureGates.initialize(featureGatesOptions, {
          atlassianAccountId: userState.accountId,
          tenantId: data.workspace?.cloudId ?? undefined,
          atlassianOrgId: data.workspace?.organisationId,
        });

        await initConfigClient({
          atlassianAccountId: userState.accountId,
          tenantId: data.workspace?.cloudId ?? undefined,
          atlassianOrgId: data.workspace?.organisationId,
        });

        // We now are guaranteed to have an active workspace which the user has access to as well
        setAppState(AppState.FULLY_INITIALISED);
      }
    }
  }, []);

  useEffect(() => {
    if (appState === AppState.FULLY_INITIALISED && !workspace?.UUID) {
      captureException(new Error('InvalidAppStateException: Atlas is initialised with missing workspaceUuid'));
    }
  }, [appState, workspace, user]);

  const collabContext = useMemo<CollaborationContext>(() => {
    return {
      goalWorkspace: workspace.cloudId,
      projectWorkspace: workspace.cloudId,
      rovoWorkspace: workspace.cloudId,
    };
  }, [workspace.cloudId]);

  if (qsSrc === 'signup' || (Boolean(qsStartCrossFlow) && matchedRoute?.route.name !== ONBOARDING_PAGE)) {
    const searchParams = createSearchParamsFromScratch({ cloudId });
    if (qsStartCrossFlow) {
      searchParams.append('startCrossFlowSrcContext', qsStartCrossFlow);
      searchParams.append('continue', qsStartCrossFlowContinueUrl);
    }

    props.history.replace(`/hello?${searchParams.toString()}`);
    return null;
  }

  if (!Object.keys(user).length || appState === AppState.LOADING) {
    return <FullScreenSpaLoader />;
  }

  return (
    <HelpContextProvider>
      <CollabContext.Provider value={collabContext}>
        {props.children(user, workspace, featureFlagClient)}
      </CollabContext.Provider>
    </HelpContextProvider>
  );
};
