/**
 * @generated SignedSource<<cc9d2c3461f7a1ea1532ccb7c0fde3cb>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: yarn relay
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { Fragment, ReaderFragment } from 'relay-runtime';
import type { FragmentRefs } from "relay-runtime";
export type BrowseAllScreenQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"BrowseDepartmentSection" | "BrowseJobTitleSection" | "BrowseKudosSection" | "BrowseLocationSection" | "BrowseTeamSection">;
  readonly " $fragmentType": "BrowseAllScreenQuery";
};
export type BrowseAllScreenQuery$key = {
  readonly " $data"?: BrowseAllScreenQuery$data;
  readonly " $fragmentSpreads": FragmentRefs<"BrowseAllScreenQuery">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "kind": "Variable",
  "name": "first",
  "variableName": "first"
},
v1 = {
  "kind": "Variable",
  "name": "workspaceId",
  "variableName": "workspaceId"
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cloudId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "kudosFirst"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "kudosQuery"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organisationId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "query"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "workspaceId"
    }
  ],
  "kind": "Fragment",
  "name": "BrowseAllScreenQuery",
  "selections": [
    {
      "args": [
        {
          "kind": "Variable",
          "name": "after",
          "variableName": "after"
        },
        {
          "kind": "Variable",
          "name": "cloudId",
          "variableName": "cloudId"
        },
        (v0/*: any*/),
        {
          "kind": "Variable",
          "name": "organisationId",
          "variableName": "organisationId"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "query"
        }
      ],
      "kind": "FragmentSpread",
      "name": "BrowseTeamSection"
    },
    {
      "args": [
        {
          "kind": "Variable",
          "name": "first",
          "variableName": "kudosFirst"
        },
        {
          "kind": "Variable",
          "name": "query",
          "variableName": "kudosQuery"
        },
        (v1/*: any*/)
      ],
      "kind": "FragmentSpread",
      "name": "BrowseKudosSection"
    },
    {
      "args": (v2/*: any*/),
      "kind": "FragmentSpread",
      "name": "BrowseDepartmentSection"
    },
    {
      "args": (v2/*: any*/),
      "kind": "FragmentSpread",
      "name": "BrowseLocationSection"
    },
    {
      "args": (v2/*: any*/),
      "kind": "FragmentSpread",
      "name": "BrowseJobTitleSection"
    }
  ],
  "type": "Query"
};
})();

(node as any).hash = "3508d2c2f65a7ce49bc6036dd18825e7";

export default node;
