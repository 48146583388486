import React from 'react';
import { graphql, useFragment } from 'react-relay';

import { TeamPopup } from '@townsquare/onboarding';

import { BrowseDepartmentsSection, BrowseJobTitleSection, BrowseLocationSection } from '../BrowsePeopleFieldSection';
import { BrowseDepartmentsSectionSkeleton } from '../BrowsePeopleFieldSection/BrowseDepartmentSection';
import { BrowseJobTitleSectionSkeleton } from '../BrowsePeopleFieldSection/BrowseJobTitleSection';
import { BrowseKudosSection, BrowseKudosSectionSkeleton } from '../BrowsePeopleFieldSection/BrowseKudosSection';
import { BrowseLocationSectionSkeleton } from '../BrowsePeopleFieldSection/BrowseLocationSection';
import { BrowsePeopleSection } from '../BrowsePeopleSection';
import { PeopleRowLoadingSkeleton } from '../BrowsePeopleSection/PeopleRowLoadingSkeleton';
import { BrowseTeamsSection, BrowseTeamsSectionSkeleton } from '../BrowseTeamSection';

import { BrowseAllScreenQuery$key } from './__generated__/BrowseAllScreenQuery.graphql';

export interface BrowseAllScreenProps {
  data: BrowseAllScreenQuery$key;
}

export const BrowseAllScreenSkeleton = () => (
  <>
    <PeopleRowLoadingSkeleton />
    <BrowseTeamsSectionSkeleton />
    <BrowseKudosSectionSkeleton />
    <BrowseDepartmentsSectionSkeleton />
    <BrowseJobTitleSectionSkeleton />
    <BrowseLocationSectionSkeleton />
  </>
);

export const BrowseAllScreen = (props: BrowseAllScreenProps) => {
  const data = useFragment(
    graphql`
      fragment BrowseAllScreenQuery on Query
      @argumentDefinitions(
        query: { type: "String!" }
        kudosQuery: { type: "String!" }
        first: { type: "Int" }
        kudosFirst: { type: "Int" }
        after: { type: "String" }
        organisationId: { type: "String!" }
        cloudId: { type: "String!" }
        workspaceId: { type: "ID!" }
      ) {
        ...BrowseTeamSection
          @arguments(query: $query, organisationId: $organisationId, cloudId: $cloudId, first: $first, after: $after)
        ...BrowseKudosSection @arguments(query: $kudosQuery, workspaceId: $workspaceId, first: $kudosFirst)
        ...BrowseDepartmentSection @arguments(workspaceId: $workspaceId, first: $first)
        ...BrowseLocationSection @arguments(workspaceId: $workspaceId, first: $first)
        ...BrowseJobTitleSection @arguments(workspaceId: $workspaceId, first: $first)
      }
    `,
    props.data,
  );

  return (
    <>
      <BrowsePeopleSection />
      <BrowseTeamsSection data={data} />
      <BrowseKudosSection data={data} />
      <BrowseDepartmentsSection data={data} />
      <BrowseJobTitleSection data={data} />
      <BrowseLocationSection data={data} />
      <TeamPopup />
    </>
  );
};
