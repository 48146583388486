import FeatureGates from '@atlaskit/feature-gate-js-client';
import { type PropsWithChildren } from 'react';

import { GateNames } from './gate-keys';

// Atlas is in maintenance mode, so we want to ensure that it cannot be inadvertently affected by updates to
// feature gates that are intended to affect Atlassian Home only (they share the same statsig product key).
// Some gates, like kill switches, which should still be actively controlled via statsig are omitted here.
// This is done as part of PIR-22520
const STABLE_FEATURE_GATES: { [key in GateNames]?: boolean } = {
  enable_suggested_contributors: false,
  atlas_enable_dashboard_route: false,
  enable_highlights_on_directories: false,
  enable_mitigating_and_resolving_risks: false,
  expanded_topic_page: false,
  townsquare_okr_goal_types: false,
  jira_nav4: false,
  jira_nav4_dogfooding: false,
  jira_nav4_eap: false,
  atlassian_home_visual_refresh: false,
  atlas_enable_linked_focus_areas: false,
  'atlas-enable-capability-router': false,
  'timeline-view-for-goals': false,
  'platform-visual-refresh-icons': false,
};

export const useFeatureGate = (gateName: GateNames): boolean => {
  // Need to update a bunch of tests first - https://product-fabric.atlassian.net/browse/TC-8019
  // if (!FeatureGates.initializeCompleted()) {
  //   throw new Error(
  //     'useFeatureGate was called with Feature gates not initialized! Your component is probably rendered outside of <FeatureGatesInitialization>',
  //   );
  // }

  return STABLE_FEATURE_GATES[gateName] ?? FeatureGates.checkGate(gateName);
};

export function FeatureGate(props: PropsWithChildren<{ gateName: GateNames }>) {
  const isGateEnabled = useFeatureGate(props.gateName);

  if (typeof props.children === 'function') {
    return props.children({ isGateEnabled });
  }

  return isGateEnabled ? props.children : null;
}

export function isGateEnabled(gateName: GateNames): boolean {
  // Need to update a bunch of tests first - https://product-fabric.atlassian.net/browse/TC-8019
  // if (!FeatureGates.initializeCompleted()) {
  //   throw new Error(
  //     'isGateEnabled was called with Feature gates not initialized! Your component is probably rendered outside of <FeatureGatesInitialization>',
  //   );
  // }

  return FeatureGates.checkGate(gateName);
}
